import {BaseGraph} from "./base_graph";

class TachoFinance extends BaseGraph {
    constructor() {
        super('tacho_finance');
    }

    getChartJsOptions(options, data) {
        options.plugins = {
            legend: {
                display: false
            },
        };
        
        return options;
    }
}

$(() => {
    if(app && app.DASHBOARD_CORE) {
        new TachoFinance();
    } else {
        console.warn('DashboardCore not initalized.');
    }
});
